<template>
    <div class="map-div">
        <bread-crumb class="center"></bread-crumb>
        <div class="operate-panel">
            <el-form class="search-from" @submit.native.prevent>
                <el-input placeholder="请输入内容" v-model="keyword" clearable class="input-with-select" @ @keyup.enter.native="handleSearch">
                    <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
                </el-input>
            </el-form>
            <div class="search-cont">
                <!-- 搜索结果列表 -->
                <ul class="result-list" v-if="resultList">
                    <li class="every" v-for="(item,index) of resultData" :key="item.id">
                        <div class="listItem" @click="centerPark(item)">

                            <el-row :gutter="24" >
                                <el-col :span="16" class="title">
                                    <span style="color: #0A7DFF;font-size: 16px">{{item.djdymc}}</span>
                                </el-col>
                                <el-col :span="8">
                                    <img :src="item.ydlj" style="width: 70px;height: 50px"/>
                                </el-col>
                            </el-row>
                            <div class="info">
                                <el-row :gutter="24" >
                                    <el-col :span="12">
                                        <i class="icon icon-location"></i>
                                        <span>{{item.dq}}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <i class="icon icon-date"></i>
                                        <span>{{item.djsj}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <i class="icon icon-type"></i>
                                        <span>{{item.zylx}}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <i class="icon icon-area"></i>
                                        <span>{{item.mj}}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            <el-row class="btn" :gutter="24" style="text-align: center">
                                <el-col :span="12">
                                    <el-button round type="primary" size="small" icon="el-icon-position" @click="navigateToPark(item)">导航</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button round type="primary" size="small" @click="navigateToDetail(item.id)">详情信息</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </li>
                </ul>
                <!-- 该列表用于点击地图上的图标时，打开详细信息 -->
                <ul class="result-list" v-if="showDetail">
                    <li class="every" v-for="(item,index) of detailData" :key="item.id">
                        <div class="listItem">

                            <el-row :gutter="24" >
                                <el-col :span="16" class="title">
                                    <span style="color: #0A7DFF;font-size: 18px">{{item.djdymc}}</span>
                                </el-col>
                                <el-col :span="8">
                                    <img :src="item.ydlj" style="width: 70px;height: 50px"/>
                                </el-col>
                            </el-row>
                            <div class="info">
                                <el-row :gutter="24" >
                                    <el-col :span="12">
                                        <i class="icon icon-location"></i>
                                        <span>{{item.dq}}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <i class="icon icon-date"></i>
                                        <span>{{item.djsj}}</span>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <i class="icon icon-type"></i>
                                        <span>{{item.zylx}}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <i class="icon icon-area"></i>
                                        <span>{{item.mj}}</span>
                                    </el-col>
                                </el-row>
                            </div>

                            <el-row :gutter="24" style="text-align: center" class="btn">
                                <el-col :span="12">
                                    <el-button round type="primary" size="small" icon="el-icon-position" @click="navigateToPark(item)">导航</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button round type="primary" size="small" @click="navigateToDetail(item.id)">详情信息</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div id="map"></div>
    </div>
</template>
<script>
    import breadCrumb from "../../components/common/breadCrumb";
    import {getDyDetailsInfo,getDyInfoBySearch} from "../../api/dyInfoService";

    export default {
        data() {
            return {
                map: {},
                zoom: 5,
                searchResult: '',
                hotParkList: [],
                resultList: false,
                resultDetail: false,
                showDetail: false,
                itemDetail: '',
                keyword: '',
                resultData: [],
                detailList: [],
                detailData: [],
                drivingRoute: '',   //驾驶线路
                startIcon: 'http://lbs.tianditu.gov.cn/images/bus/start.png',  //起点标记图标
                endIcon: 'http://lbs.tianditu.gov.cn/images/bus/end.png',  //重点标记图标
                startLngLat: '',      //起点经纬度
                endLngLat: '',      //终点经纬度
                mapCenterLng: 105.71223,     //地图中心点经度
                mapCenterLat: 34.58257,     //地图中心点纬度
            }
        },
        components: {
          breadCrumb
        },
        mounted() {
            this.loadMap()
        },
        methods: {
            loadMap() {
                this.map = new T.Map("map")
                this.map.centerAndZoom(new T.LngLat(this.mapCenterLng, this.mapCenterLat), this.zoom)
                getDyInfoBySearch({
                    start: 0,
                    size: 10
                }).then( response => {
                    this.hotParkList = response.data
                    for(let i = 0; i < this.hotParkList.length; i++){
                        // getDyDetailsInfo(this.hotParkList[i].id).then(response => {
                        //     if(response.data.qszk != null && response.data.qszk.qlxzfs == '直接行使'){
                        //         this.hotParkList[i].djjb = '国家级'
                        //     }else{
                        //         this.hotParkList[i].djjb = '省级'
                        //     }
                        // })
                        let marker = new T.Marker(new T.LngLat(this.hotParkList[i].longitude,this.hotParkList[i].latitude))
                        let content = this.hotParkList[i].djdymc

                        // if(this.hotParkList[i].dtlj != null) {
                        //     let icon = new T.Icon({
                        //         iconUrl: this.hotParkList[i].dtlj,
                        //         iconSize: new T.Point(30, 30),
                        //         iconAnchor: new T.Point(15, 15)
                        //     })
                        //     marker.setIcon(icon)
                        // }else{
                            let icon = new T.Icon({
                                iconUrl: this.hotParkList[i].ydlj,
                                iconSize: new T.Point(30,30),
                                iconAnchor: new T.Point(15,15)
                            })
                            marker.setIcon(icon)
                        // }
                        this.map.addOverLay(marker)

                        marker.addEventListener('click',() => {
                            marker.openInfoWindow(new T.InfoWindow(content))
                            this.resultList = false
                            this.showDetail = true
                            this.detailData.splice(0,1)
                            this.detailData.push(this.hotParkList[i])
                            this.mapCenterLng = this.hotParkList[i].longitude
                            this.mapCenterLat = this.hotParkList[i].latitude
                            this.map.centerAndZoom(new T.LngLat(this.mapCenterLng, this.mapCenterLat), 12)
                        })
                    }
                    // this.map.addEventListener('zoomend', ()=> {
                    //     let currentZoom = this.map.getZoom()
                    //
                    // })
                })
            },
            centerPark(item) {
                this.map.centerAndZoom(new T.LngLat(item.longitude, item.latitude), 12)
            },
            navigateToDetail(id) {     //跳转
                console.log(id)
                window.open('/info/infoDetail?zrzyDyId='+id)
                // this.$router.push({
                //     path: '/info/infoDetail?id='+id
                // })
            },
            handleSearch() {
                if(this.keyword == ''){
                    console.log('查询条件为空')
                }else {
                    getDyInfoBySearch({    //根据输入的名称查询
                        djdymc: this.keyword
                    }).then(response => {
                        if (response != null) {
                            this.resultList = true
                            this.showDetail = false
                            this.resultData = response.data
                            this.map.clearOverLays()
                            let markers = []
                            for (let i = 0; i < this.resultData.length; i++) {
                                let marker = new T.Marker(new T.LngLat(this.resultData[i].longitude, this.resultData[i].latitude))
                                let content = this.resultData[i].djdymc
                                // if(this.resultData[i].dtlj != null) {
                                //     let icon = new T.Icon({
                                //         iconUrl: this.resultData[i].dtlj,
                                //         iconSize: new T.Point(30, 30),
                                //         iconAnchor: new T.Point(15, 15)
                                //     })
                                //     marker.setIcon(icon)
                                // }else{
                                    let icon = new T.Icon({
                                        iconUrl: this.resultData[i].ydlj,
                                        iconSize: new T.Point(30,30),
                                        iconAnchor: new T.Point(15,15)
                                    })
                                    marker.setIcon(icon)
                                // }
                                marker.addEventListener("click", () => {
                                    let markerInfo = new T.InfoWindow(content)
                                    marker.openInfoWindow(markerInfo)
                                    this.map.centerAndZoom(marker.getLngLat(),12)
                                    this.showDetail = true
                                    this.resultList = false
                                    this.detailData.splice(0,1)
                                    this.detailData.push(this.resultData[i])
                                })
                                markers.push(marker.getLngLat())
                                this.map.addOverLay(marker)
                            }
                            this.map.setViewport(markers)    //为查询结果设置最佳比例尺
                        }
                    })
                }
            },
            navigateToPark(item) {     //导航启用
                this.map.clearOverLays()
                // let configuration = {
                //     onSearchComplete: this.drivingResult
                // }
                this.endLngLat = new T.LngLat(item.longitude, item.latitude)

                let lc = new T.LocalCity()
                lc.location((response) => {  //获取当前的位置
                    this.startLngLat = response.lnglat
                    this.drivingRoute.search(this.startLngLat, this.endLngLat)
                })
                    this.drivingRoute = new T.DrivingRoute(this.map)
                    this.drivingRoute.setPolicy(1)
                    this.drivingRoute.setSearchCompleteCallback(this.drivingResult)
            },
            drivingResult(result) {      //导航的回调函数
                this.createStartEndMarker(result)
                let routes = result.getNumPlans()     //返回方案个数
                for(let i = 0; i<routes; i++){
                    let plan = result.getPlan(i)
                    this.createRoute(plan.getPath())        //绘制路线
                    this.map.setViewport(plan.getPath())      //用最佳比例尺来显示结果
                }
            },
            createStartEndMarker(result){      //为查询结果添加起点和终点标记
                let startMarker = new T.Marker(result.getStart(), {
                    icon: new T.Icon({
                        iconUrl: this.startIcon,
                        iconSize: new T.Point(44,34),
                        iconAnchor: new T.Point(12,31)
                    })
                })
                this.map.addOverLay(startMarker)
                let endMarker = new T.Marker(result.getEnd(), {
                    icon: new T.Icon({
                        iconUrl: this.endIcon,
                        iconSize: new T.Point(44,34),
                        iconAnchor: new T.Point(12,31)
                    })
                })
                this.map.addOverLay(endMarker)
            },
            createRoute(Lnglats) {
                let line = new T.Polyline(Lnglats, {color: "#2C64A7", weight: 5, opacity: 0.9})
                this.map.addOverLay(line)
            }
        }
    }
</script>
<style lang="scss">
    .map-div{
        position: relative;
        z-index: 1;
        .operate-panel{
            position:absolute;
            width:340px;
            left:10%;
            top:75px;
            z-index:1002;
            margin-left:10px;
            .search-from{
                .el-input-group__append{
                    background-color:#039BE5;
                    border-color:#409EFF;
                    .el-icon-search{
                        font-size:20px;
                        color:#fff;
                    }
                }
            }
            .search-cont{
                background-color:#fff;
                margin-top:10px;
                max-height:500px;
                overflow-y:auto;
                box-shadow:1px 2px 1px rgba(0,0,0,.15);
                .text{
                    width:200px;
                    margin-right:20px;
                    .title{
                        color:#039BE5;
                        font-size:16px;
                        position:relative;
                        .sort{
                            position:absolute;
                            top:-4px;
                            left:-26px;
                            width:20px;
                            height:24px;
                            color:#fff;
                            font-size:14px;
                            text-align:center;
                            line-height:24px;
                            background:url(../../assets/images/icon_sort.png) center no-repeat;
                        }
                    }
                    .location{
                        font-size:14px;
                        color:#4D4D4D;
                        margin-top:28px;
                    }
                }
                .result-list{
                    padding:20px;
                    .every{
                        padding:10px 0;
                        border-bottom:1px solid #e3dfee;
                        .el-image{
                            width:80px;
                            height:60px;
                        }
                    }
                    .every:hover{
                        background-color:#f6f6f6;
                        cursor:pointer;
                    }
                }
                .result-detail{
                    padding:0 20px 20px;
                    .back{
                        border-bottom:1px solid #E6E6E6;
                        span{
                            line-height:40px;
                            color:#808080;
                            cursor:pointer;
                        }
                    }
                    .navigator{
                        padding:14px 20px;
                        margin:5px -10px;
                        background-color:rgba(3,155,229,0.8);
                        .text{
                            width:100%;
                            margin-right:0;
                            span{
                                color:#fff;
                            }
                            .location{
                                height:26px;
                                line-height:26px;
                                margin-top:16px;
                                .el-icon-location-outline{
                                    color:#fff;
                                    font-size:16px;
                                    margin-right:10px;
                                }
                                .el-button.is-round{
                                    float:right;
                                    padding:2px 12px;
                                    border-color:#fff;
                                    font-size:14px;
                                    background-color:rgba(3,155,229,0.8);
                                }
                            }

                        }
                    }
                    .info-list{
                        border-bottom:1px solid #E6E6E6;
                        padding:10px 0;
                        .list{
                            font-size:14px;
                            color:rgba(76,76,76,1);
                            line-height:34px;
                            .name{
                                padding-left:20px;
                                background:left center no-repeat;
                            }
                            .type{
                                background-image:url(../../assets/images/icon_type2.png)
                            }
                            .level{
                                background-image:url(../../assets/images/icon_level2.png)
                            }
                            .area{
                                background-image:url(../../assets/images/icon_area2.png)
                            }
                            .number{
                                background-image:url(../../assets/images/icon_number2.png)
                            }
                            .date{
                                background-image:url(../../assets/images/icon_date2.png)
                            }
                        }
                    }
                    .desc{
                        padding:20px 0 18px;
                        border-bottom:1px solid #E6E6E6;
                        .title{
                            font-size:16px;
                            font-weight:bold;
                            margin-bottom:15px;
                        }
                        .intro{
                            max-height:44px;
                            font-size:14px;
                            color:rgba(76,76,76,1);
                            line-height:22px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .to-detail{
                        overflow:hidden;
                        margin-top:20px;
                        span{
                            float:right;
                            width:96px;
                            line-height:32px;
                            text-align:center;
                            color:rgba(3,155,229,1);
                            background:rgba(255,255,255,1);
                            border:1px solid rgba(3,155,229,1);
                        }
                        span:hover{
                            cursor:pointer;
                            background:rgba(3,155,229,1);
                            color:rgba(255,255,255,1);
                        }
                    }
                }
            }

        }
        #map{
            margin-top: 20px;
            height: 80vh;
            width: 80%;
            left: 10%;
        }
        .tip-tab{
            position: absolute;
            top: 58px;
            background-color: #FFF;
            color: black;
            font-size: 16px;
            z-index: 2;
        }

        .icon {
            float: left;
            width: 18px;
            height: 18px;
            margin: 8px 5px 0 0;
            background: center no-repeat;
        }

        .icon-type {
            background-image: url(../../assets/images/icon_type.png);
        }

        .icon-area {
            background-image: url(../../assets/images/icon_area.png);
        }

        .icon-location {
            background-image: url(../../assets/images/icon_location.png);
        }

        .icon-date {
            background-image: url(../../assets/images/icon_date.png);
        }
    }

    .listItem{
        width: 100%;
        line-height: 35px;
        .title{
            max-height:37px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
        .info{
            margin:10px 0;
            .el-col-12{
                max-height:35px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
        }
        .btn{
            .el-button{
                width:90px;
            }
        }
    }

    .search-cont li:hover{
        background-color:#f6f6f6;
        cursor:pointer;
    }
</style>
