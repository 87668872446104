<template>
    <div class="bread-crumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="item in breadList" :key="item.name" :to="{path:item.path}">{{ item.title }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
    export default {
        name: 'breadCrumb',
        data() {
            return {
                crumb: 'd',
                allRouter: [], // 当前所有路由
                breadList: [] // 显示面包屑
            }
        },
        methods: {
            getTitle() {
              if (this.allRouter.length === 0) {
                this.allRouter = this.$router.options.routes;
              }
              let path = this.$route.path.split('/')
              let query = this.$route.query;
              let param = '';
              if (query !== null && query !== undefined) {
                for (let key in query) {
                  param = param + key + '=' + query[key] + '&'
                }
              }
              param = '?' + param;

              //第一项是否为空 空就删除
              if (path[0] === '') {
                path = path.splice(1, path.length);
              }
              for (let i = 0; i < path.length; i++) {
                let pathurl = ''
                //拼装地址
                for (let k = 0; k <= i; k++) {
                  pathurl = pathurl + '/' + path[k]
                }
                //验证地址
                for (let y = 0; y < this.allRouter.length; y++) {
                  if (pathurl !== '/' && pathurl === this.allRouter[y].path) {
                    const rou = {'path': this.allRouter[y].path + param, 'title': this.allRouter[y].meta.title}
                    this.breadList.push(rou);
                    break
                  }
                }

              }
            }
        },
        created() {
            this.breadList = [];
            this.getTitle()
        }
    }
</script>
<style lang="scss">
    .bread-crumb {
        .el-breadcrumb {
            line-height: 39px;
            padding-left: 10px;
            border-bottom: 1px solid #D9D9D9;

            .el-breadcrumb__inner.is-link {
                font-weight: 500;
            }
        }
    }
</style>
